// Bootstrap customisations for Private Client
// To generate the custom build of Bootstrap:
// 1. download and extract the source files from https://getbootstrap.com/docs/4.6/getting-started/download/
// 2. Copy this file and _buttons-pclient.scss into the scss folder
// 3. Build as described here https://getbootstrap.com/docs/4.6/getting-started/build-tools/
// You'll end up with two CSS files in the dist\css folder. bootstrap.css is standard Bootstrap, and bootstrap-pclient.css is the custom build
// The standard build script doesn't automatically generate a minified version, which you don't strictly speaking need, but it's nice to have
// So you have to edit package.json in the Bootstrap source folder to tell it to include it
// Change this line
// "css-minify": "cleancss -O1 --format breakWith=lf --source-map --source-map-inline-sources --output dist/css/bootstrap.min.css dist/css/bootstrap.css && cleancss -O1 --format breakWith=lf --source-map --source-map-inline-sources --output dist/css/bootstrap-grid.min.css dist/css/bootstrap-grid.css && cleancss -O1 --format breakWith=lf --source-map --source-map-inline-sources --output dist/css/bootstrap-reboot.min.css dist/css/bootstrap-reboot.css",
// to this
// "css-minify": "cleancss -O1 --format breakWith=lf --source-map --source-map-inline-sources --output dist/css/bootstrap.min.css dist/css/bootstrap.css && cleancss -O1 --format breakWith=lf --source-map --source-map-inline-sources --output dist/css/bootstrap-grid.min.css dist/css/bootstrap-grid.css && cleancss -O1 --format breakWith=lf --source-map --source-map-inline-sources --output dist/css/bootstrap-reboot.min.css dist/css/bootstrap-reboot.css && cleancss -O1 --format breakWith=lf --source-map --source-map-inline-sources --output dist/css/bootstrap-pclient.min.css dist/css/bootstrap-pclient.css",

// Declare $black and $white so we can use them in this file

$black: #000;
$white: #fff;

// Create variables for our corporate colours

$onegroupblack:     #2c3341;
$onegroupgrey:      #999999;
$onegroupmidgrey:   #404855;
$onegrouplightgrey: #ececec;
$globalrisq:        #c3278b;
$amlsearch:         #e61b53;
$eidv:              #ef7e25;
$planval:           #3fa9f5;
$pclient:           #9966ff;
$pprotect:          #00a89c;
$compliant:         #39b54a;
$alerta:            #ff931e;
$archive:           #3f7af5;
$alertb:            #e61b53;

// Add them to the map

$colors: (
    "onegroupblack":     $onegroupblack,
    "onegroupgrey":      $onegroupgrey,
    "onegroupmidgrey":   $onegroupmidgrey,
    "onegrouplightgrey": $onegrouplightgrey,
    "globalrisq":        $globalrisq,
    "amlsearch":         $amlsearch,
    "eidv":              $eidv,
    "planval":           $planval,
    "pclient":           $pclient,
    "pprotect":          $pprotect,
    "compliant":         $compliant,
    "alerta":            $alerta,
    "archive":           $archive,
    "alertb":            $alertb
);

// Change the Bootstrap defaults for $primary, $secondary, and $dark to our corporate colours
// and add new $lightgrey and $midgrey themes
    
$primary:   $pclient;
$secondary: $onegroupgrey;
$dark:      $onegroupblack;
$lightgrey: $onegrouplightgrey;
$midgrey:   $onegroupmidgrey;

// Add the amended themes to the theme map, and create some new ones for our new colours

$theme-colors: (
    "primary":    $primary,
    "secondary":  $secondary,
    "dark":       $dark,
    "midgrey":    $midgrey,
    "lightgrey":  $lightgrey,
    "globalrisq": $globalrisq,
    "amlsearch":  $amlsearch,
    "pprotect":   $pprotect,
    "planval":    $planval,
    "pclient":    $pclient,
    "compliant":  $compliant,
    "alerta":     $alerta,
    "archive":    $archive,
    "alertb":     $alertb
);

// Add an extra XXL size

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
);

// Add Metropolis at the top of the list of Bootstrap fonts so it will use it if available
// (it will always be available because we include it on the site)

$font-family-sans-serif: Metropolis, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

// Enable responsive font sizes (disabled by default in standard Bootstrap)

$enable-responsive-font-sizes: true;

// Customise popovers

$popover-bg:           $lightgrey;
$popover-border-color: $black;

// Customise modal header padding

$modal-header-padding-y: 0.5rem;
$modal-header-padding-x: 0.5rem;

// Customise breadcrumbs

$breadcrumb-bg:      $white;
$breadcrumb-divider: quote("|");

// Customise horizontal divider

$hr-border-color: $white;
$hr-margin-y:     0;

// Now include all of Bootstrap, except replace the standard buttons.scss with my slightly customised version

/*!
 * Bootstrap v4.6.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

@import "functions";
@import "variables";
@import "mixins";
@import "root";
@import "reboot";
@import "type";
@import "images";
@import "code";
@import "grid";
@import "tables";
@import "forms";
@import "buttons-pclient";
@import "transitions";
@import "dropdown";
@import "button-group";
@import "input-group";
@import "custom-forms";
@import "nav";
@import "navbar";
@import "card";
@import "breadcrumb";
@import "pagination";
@import "badge";
@import "jumbotron";
@import "alert";
@import "progress";
@import "media";
@import "list-group";
@import "close";
@import "toasts";
@import "modal";
@import "tooltip";
@import "popover";
@import "carousel";
@import "spinners";
@import "utilities";
@import "print";

// Responsive borders

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .border#{$infix} { border: $border-width solid $border-color; }
    .border#{$infix}-top { border-top: $border-width solid $border-color; }
    .border#{$infix}-right { border-right: $border-width solid $border-color; }
    .border#{$infix}-bottom { border-bottom: $border-width solid $border-color; }
    .border#{$infix}-left { border-left: $border-width solid $border-color; }

    .border#{$infix}-0 { border: 0 !important; }
    .border#{$infix}-top-0 { border-top: 0 !important; }
    .border#{$infix}-right-0 { border-right: 0 !important; }
    .border#{$infix}-bottom-0 { border-bottom: 0 !important; }
    .border#{$infix}-left-0 { border-left: 0 !important; }
  }
}

// Generate a set of border options with different widths. Standard Bootstrap only allows for borders 1 pixel wide.

$border-width-custom-1: 1px !default;
$border-width-custom-2: 2px !default;
$border-width-custom-3: 3px !default;
$border-width-custom-4: 4px !default;
$border-width-custom-5: 5px !default;
$border-width-custom-6: 6px !default;
$border-width-custom-7: 7px !default;
$border-width-custom-8: 8px !default;

$border-width-customs: ("1": $border-width-custom-1, "2": $border-width-custom-2, "3": $border-width-custom-3, "4": $border-width-custom-4, "5": $border-width-custom-5, "6": $border-width-custom-6, "7": $border-width-custom-7, "8": $border-width-custom-8);

@each $name, $size in $border-width-customs {
    @each $var in '', 'top-', 'right-', 'bottom-', 'left-' {
        .border-#{$var}#{$name} { border-#{$var}width: $size !important; border-#{$var}style: solid; border-#{$var}color: $border-color;}
    }
}

// Customise definition lists

dt {
  margin-bottom: .5rem;
}

dd {
  display: flex;
  align-items: flex-end;
}
